import Vue from 'vue'
import VueRouter from 'vue-router'
import query from '@/views/search'

Vue.use(VueRouter)

const routes = [
    {path: '/', component: query},
    // {path:'*',component Purchase}
]

const router = new VueRouter({
	mode: 'hash',
    routes
})

export default router
