import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/stackoverflow-light.css'

Vue.use(VueHighlightJS)

axios.defaults.baseURL = 'https://zy.xywlapi.cc'; // 将URL替换为您的API的URL

Vue.prototype.$http = axios;

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount('#app')
