<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-row :gutter="10" style="margin: 0 auto;" type="flex" justify="center">
          <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="12" style="border-bottom: 1px solid #dcdfe6;">
            <h1 style="color: #409eff;display: inline-block"
              @click="() => { if (this.$route.path !== '/') { this.$router.push('/'); } }">爱查查</h1>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <!-- 居中布局 -->
        <el-row :gutter="10" style="margin: 0 auto;" type="flex" justify="center">
          <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="12">
            <router-view></router-view>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import search from "@/views/search.vue";

export default {
  components: {
    search,
  },
  created() {
    console.log(
      "\n %c  爱查查   %c 看你妈等下就开你户口 \n",
      "color: #ffffff; background: #f1404b; padding:5px 0;",
      "background: #030307; padding:5px 0;color: #ffffff;"
    );
  },
};
</script>

<style scoped>
.router-link-active {
  border-bottom: 2px solid #409eff;
}

ul,
li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

li {
  margin-left: 25px;
  color: #1989faa6;
}

li:hover {
  color: #1989fa;
}
</style>

<style>
html body {
  padding: 0;
  margin: 0;
}

/*body {*/
/*  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);*/
/*  background-repeat: no-repeat;*/
/*  background-attachment: fixed;*/
/*}*/

.el-header {
  color: #333;
  line-height: 22px;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>