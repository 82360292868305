<template>
  <div>
    <el-input placeholder="请输入内容" v-model="userInput" class="input-with-select">
      <el-select v-model="selectValue" slot="prepend" placeholder="请选择">
        <el-option label="qq查手机号" value="1"></el-option>
        <el-option label="手机号查qq" value="2"></el-option>
        <el-option label="qq查询lol" value="3"></el-option>
        <el-option label="lol查qq" value="4"></el-option>
        <el-option label="qq查老密" value="5"></el-option>
        <el-option label="微博id查手机号" value="6"></el-option>
        <el-option label="手机号查微博id" value="7"></el-option>
      </el-select>
      <el-button slot="append" icon="el-icon-search" @click="getData"></el-button>
    </el-input>
    <el-card v-loading="loading" v-if="apiResult != ''" class="box-card">
      <pre v-highlightjs style="
          font-family: 'Helvetica Neue', Helvetica, 'PingFang SC',
            'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
        ">{{ apiResult }}
      </pre>
    </el-card>
    <el-empty v-else description="没有数据"></el-empty>
  </div>
</template>

<style>
.el-select .el-input {
  width: 200px;
}

@media (max-width: 767px) {
  .el-select .el-input {
    width: 140px !important;
  }
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>

<style scoped>
.box-card {
  margin-top: 20px;
}
</style>

<script>
export default {
  data() {
    return {
      userInput: "",
      selectValue: "",
      apiResult: "",
      loading: false,
    };
  },
  created() { },
  methods: {
    async getData() {
      if (!this.selectValue) {
        this.$notify.error({
          title: "错误",
          message: "请选择查询的类型",
        });
        return;
      }
      if (this.userInput.length < 1) {
        this.$notify.error({
          title: "错误",
          message: "请输入内容在进行查询",
        });
        return;
      }
      this.loading = true;
      this.$message({
        showClose: true,
        message: "正在拼命查询中...",
        type: "success",
      });
      var searchType;
      var value;

      switch (Number(this.selectValue)) {
        case 1:
          searchType = "qqapi";
          value = "qq=" + this.userInput;
          break;
        case 2:
          searchType = "qqphone";
          value = "phone=" + this.userInput;
          break;
        case 3:
          searchType = "qqlol";
          value = "qq=" + this.userInput;
          break;
        case 4:
          searchType = "lolname";
          value = "name=" + this.userInput;
          break;
        case 5:
          searchType = "qqlm";
          value = "qq=" + this.userInput;
          break;
        case 6:
          searchType = "wbapi";
          value = "id=" + this.userInput;
          break;
        case 7:
          searchType = "wbphone";
          value = "phone=" + this.userInput;
          break;
      }

      await this.$http
        .get("/" + searchType + "?" + value)
        .then((response) => {
          this.loading = false;
          this.apiResult = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.$notify.error({
            title: "错误",
            message: error,
          });
          // 处理请求失败的错误
          console.error(error);
        });
    },
  },
};
</script>
